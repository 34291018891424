import {FC} from 'react';
import {Theme, useMediaQuery} from '@mui/material';
import IconCloud from 'components/page/leadgeneration/img/svg/cloud.svg';
import IconGarden from 'components/page/leadgeneration/img/svg/garden.svg';
import IconHouseSm from 'components/page/leadgeneration/img/svg/house-sm.svg';
import IconCloudLg from 'components/page/leadgeneration/img/svg/cloud-lg.svg';
import IconApartmentSm from 'components/page/leadgeneration/img/svg/aparment.svg';
import IconFamilyHouse from 'components/page/leadgeneration/img/svg/family-house.svg';
import {AbsoluteIcon} from 'components/page/leadgeneration/components/initial/head/absoluteIcons/AbsoluteIcon';
import { Svg } from 'modules/theme/components/svg/Svg';


export const AbsoluteIcons: FC = ({}) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <>
        <AbsoluteIcon
            top={-60}
            left={180}
            hidden={isMobile}
        >
            <Svg component={IconHouseSm} width={90} height={90} />
        </AbsoluteIcon>

        <AbsoluteIcon
            top={50}
            left={15}
            hidden={isMobile}
        >
            <Svg component={IconCloud} width={60} height={37} />
        </AbsoluteIcon>

        <AbsoluteIcon
            right={15}
            bottom={80}
            hidden={isMobile}
        >
            <Svg component={IconCloudLg} width={70} height={41} />
        </AbsoluteIcon>

        <AbsoluteIcon
            left={75}
            bottom={-55}
            hidden={isMobile}
        >
            <Svg component={IconApartmentSm} width={182} height={161} />
        </AbsoluteIcon>

        <AbsoluteIcon
            top={-65}
            right={75}
            hidden={isMobile}
        >
            <Svg component={IconFamilyHouse} width={193} height={161} />
        </AbsoluteIcon>

        <AbsoluteIcon
            right={110}
            bottom={-20}
            hidden={isMobile}
        >
            <Svg component={IconGarden} width={108} height={108} />
        </AbsoluteIcon>
    </>;
};

