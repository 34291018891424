import {FC, ReactNode} from 'react';
import {Box} from '@mui/material';

interface IAbsoluteIcon {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    hidden?: boolean;
    children: ReactNode | ReactNode[];
}

export const AbsoluteIcon: FC<IAbsoluteIcon> = ({
    hidden,
    top = 'auto',
    left = 'auto',
    right = 'auto',
    bottom = 'auto',
    children,
}) => {
    return <Box
        position="absolute"
        top={top && `${top}px`}
        left={left && `${left}px`}
        right={right && `${right}px`}
        bottom={bottom && `${bottom}px`}
        display={hidden ? 'none' : 'block'}
    >
        {children}
    </Box>;
};

