import {Text} from 'modules/theme/components/text/Text';
import {FC} from 'react';

interface ILabel {
    text: string;
}

export const Label: FC<ILabel> = ({text}) => {
    return <Text variant="body2" bold mb={1}>
        {text}
    </Text>;
};
