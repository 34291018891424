import * as Yup from 'yup';
import { IFormData } from 'components/page/leadgeneration/interfaces/IFormData';

const formValidationSchema = Yup.object().shape<Record<keyof IFormData, Yup.AnySchema>>({
    name: Yup
        .string()
        .min(3, 'Meno musí mať aspoň 3 znaky')
        .max(50, 'Meno môže mať maximálne 50 znakov')
        .required('Meno je povinné'),
    email: Yup
        .string()
        .email('Neplatný formát emailu')
        .required('Email je povinný'),
    location: Yup
        .object(),
    phoneNumber: Yup
        .string()
        .trim()
        .matches(/^(?:02\/?(?:[0-9]\s*){8}|0[3-5][1-9]\/?[0-9]{7}|(?:\+421|0)\s*(?:[0-9]\s*){9}|\+420\s*(?:[0-9]\s*){9})$/, 'Číslo je v nesprávnom formáte.')
        .required('Číslo je povinné'),
    type: Yup
        .string()
        .required('Typ je povinný'),
    district: Yup
        .string()
        .required('Okres je povinný'),
    usableArea: Yup
        .number()
        .min(1, 'Užitková plocha je povinná')
        .required('Užitková plocha je povinná'),
    agreeToTerms: Yup
        .boolean()
        .oneOf([true], 'Súhlas je povinný'),
    agreeToGDPR: Yup
        .boolean()
        .oneOf([true], 'Súhlas je povinný'),
    recaptchaToken: Yup
        .string()
        .required('Je potrebné vyplniť reCAPTCHA')
});
export default formValidationSchema;
