import {FC} from 'react';
import {Box, Palette} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import IconThumbsUp from 'components/page/leadgeneration/img/svg/thums-up.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

export const Description: FC = () => {

    return <>
        <Text variant="body2" center color={(palette: Palette) => palette.labelSecondary} mb={2}>
            V krátkom čase vás budeme kontaktovať telefonicky, prípadne mailom a dohodneme sa na ďalšom postupe.
        </Text>

        <Box display="flex" justifyContent="center">
            <Svg component={IconThumbsUp} width={215} height={215} />
        </Box>
    </>;
};
