/* eslint-disable */
import {
    MenuItem,
    InputLabel,
    FormControl,
    InputAdornment,
    Select as MuiSelect,
    useTheme,
    FormHelperText,
} from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { FC, FunctionComponent, SVGProps } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IOption } from 'modules/theme/components/fields/select/interfaces/IOption';

interface ISelect {
    label?: string;
    errorMsg?: string;
    options: IOption[];
    disabled?: boolean;
    placeholder?: string;
    selectedValue: string;
    onChange: (selectedValue: string) => void;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    testId?: string;
    onBlur?: () => void;
}

export const Select: FC<ISelect> = ({
    icon,
    label,
    options,
    onChange,
    errorMsg,
    disabled,
    placeholder,
    selectedValue,
    testId = 'select',
    onBlur,
}) => {
    const { palette, typography } = useTheme();
    if (label && icon) {
        throw Error('You cannot use label and icon at same time. ');
    }

    const finallOptions: IOption[] = placeholder
        ? [{ name: placeholder, value: '', isDisabled: true }, ...options]
        : options;

    const menuItems = finallOptions.map((option: IOption) => (
        <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.isDisabled}
            selected={option.value === selectedValue}
            className="UCRealEstateDesignSystem-SelectMenuItem"
            sx={{
                fontWeight: option.isParent
                    ? typography.fontWeightBold
                    : typography.fontWeightRegular,
                color:
                    option.value === ''
                        ? palette.labelSecondary.main
                        : palette.labelPrimary.main,
                background:
                    option.isParent &&
                    palette.backgroundSecondary.main + '!important',
            }}
        >
            {option.name}
        </MenuItem>
    ));

    return (
        <FormControl fullWidth error={!!errorMsg}>
            {label && <InputLabel>{label}</InputLabel>}

            <MuiSelect
                fullWidth
                displayEmpty
                error={!!errorMsg}
                disabled={disabled}
                value={selectedValue}
                placeholder={placeholder}
                data-test-id={testId}
                sx={{
                    color:
                        selectedValue === ''
                            ? palette.labelSecondary.main
                            : palette.labelPrimary.main,
                }}
                onChange={(e) => onChange(e.target.value)}
                onBlur={() => {
                    if (onBlur) {
                        onBlur();
                    }
                }}
                IconComponent={KeyboardArrowDownIcon}
                startAdornment={
                    icon && (
                        <InputAdornment position="start">
                            <Svg icon component={icon} width={20} />
                        </InputAdornment>
                    )
                }
            >
                {menuItems}
            </MuiSelect>
            {!!errorMsg && <FormHelperText>{errorMsg}</FormHelperText>}
        </FormControl>
    );
};
