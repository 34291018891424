import {FC} from 'react';
import {Box, Theme} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import MappinIcon from 'modules/theme/components/icons/basic/Mappin.svg';
import HouseIcon from 'modules/theme/components/icons/category/House.svg';
import HeartIcon from 'modules/theme/components/icons/basic/Heart.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

export const Headline: FC = () => {
    return <>
        <Box mb={3.5}>
            <Box display="flex" alignItems="center" justifyContent="center" gap={.5}
                color={(theme: Theme) => theme.palette.labelPrimaryInverse.main}
            >
                <Svg icon component={HouseIcon} width={28} />
                <Svg icon component={MappinIcon} width={28} />
                <Svg icon component={HeartIcon} width={28} />
            </Box>
        </Box>

        <Text mobileVariant='h3' variant={'h1'} center bold inverseColor>
            Želáte si predať nehnuteľnosť rýchlo, výhodne a bez starostí?
        </Text>
    </>;
};
