import {FC} from 'react';
import {Select} from 'components/page/leadgeneration/components/initial/form/fields/select/Select';

interface ISelectType {
    value: string;
    errMsg?: string;
    onChange: (value: string) => void;
}

export const SelectType: FC<ISelectType> = ({value, errMsg, onChange}) => {

    const options = [
        'Garsónka',
        '1 izbový byt',
        '2 izbový byt',
        '3 izbový byt',
        '4 izbový byt',
        '5 a viac izbový byt',
        'Mezonet',
        'Loft',
        'Rodinný dom',
        'Chata, chalupa',
    ];

    return <Select
        value={value}
        options={options}
        errorMsg={errMsg}
        onChange={onChange}
        placeholder="-vyberte typ-"
        label="Typ nehnuteľnosti na predaj"
    />;
};