import {useState, useEffect} from 'react';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {ILeadGenFormFields} from 'components/page/leadgeneration/interfaces/ILeadGenFormFields';
import {gaActionNamesMapper} from 'components/page/leadgeneration/mappers/gaActionNamesMapper';

interface IReturn {
    onFormInputTouch: (inputName: string, value: boolean) => void;
}

export const useLeadGenActions = (): IReturn => {
    const {gtm: {sendEvent}} = useAnalytics();
    const [formFields, setFormFields] = useState<ILeadGenFormFields>({
        name: false,
        email: false,
        phoneNumber: false,
        type: false,
        district: false,
        usableArea: false,
        agreeToTerms: false,
        agreeToGDPR: false
    });

    useEffect(() => {
        const transformFieldsToEventAction = (): string => {
            let action = ';';
            for (const field in formFields) {
                if (formFields[field]) {
                    action += `${gaActionNamesMapper[field]};`;
                } else {
                    action += ';';
                }
            }

            return action;
        };

        const onSendLeadGenEvent = () => {
            sendEvent({
                category: 'lead-gen',
                action: transformFieldsToEventAction(),
                event: 'track-event'
            });
        };

        return () => {
            onSendLeadGenEvent();
        };
    }, [formFields, sendEvent]);

    const onFormInputTouch = (inputName: string, value: boolean): void => {
        setFormFields(prevFormFields => ({...prevFormFields, [inputName]: value}));
    };

    return {
        onFormInputTouch
    };
};