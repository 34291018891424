export interface ILocation {
    id: string;
    isMain?: boolean;
    name: string;
    sef: string;
    geoPoint: IGeoPoint;
    zoom: number;
}

interface IGeoPoint {
    lon: number;
    lat: number;
}

const locations: ILocation[] = [
    {
        'id': '100000106',
        'isMain': true,
        'name': 'Banskobystrický kraj',
        'sef': 'banskobystricky-kraj',
        'geoPoint': {
            'lat': 48.55392,
            'lon': 19.22332
        },
        'zoom': 10
    },
    {
        'id': '100001043',
        'name': 'okres Banská Bystrica',
        'sef': 'banska-bystrica-okres',
        'geoPoint': {
            'lat': 48.76416,
            'lon': 19.24012
        },
        'zoom': 12
    },
    {
        'id': '100001044',
        'name': 'okres Banská Štiavnica',
        'sef': 'banska-stiavnica-okres',
        'geoPoint': {
            'lat': 48.42572,
            'lon': 18.91251
        },
        'zoom': 12
    },
    {
        'id': '100001045',
        'name': 'okres Brezno',
        'sef': 'brezno-okres',
        'geoPoint': {
            'lat': 48.73395,
            'lon': 19.65106
        },
        'zoom': 12
    },
    {
        'id': '100001046',
        'name': 'okres Detva',
        'sef': 'detva-okres',
        'geoPoint': {
            'lat': 48.55866,
            'lon': 19.46377
        },
        'zoom': 12
    },
    {
        'id': '100001047',
        'name': 'okres Krupina',
        'sef': 'krupina-okres',
        'geoPoint': {
            'lat': 48.30087,
            'lon': 19.03827
        },
        'zoom': 12
    },
    {
        'id': '100001048',
        'name': 'okres Lučenec',
        'sef': 'lucenec-okres',
        'geoPoint': {
            'lat': 48.33992,
            'lon': 19.64495
        },
        'zoom': 12
    },
    {
        'id': '100001049',
        'name': 'okres Poltár',
        'sef': 'poltar-okres',
        'geoPoint': {
            'lat': 48.48708,
            'lon': 19.75108
        },
        'zoom': 12
    },
    {
        'id': '100001050',
        'name': 'okres Revúca',
        'sef': 'revuca-okres',
        'geoPoint': {
            'lat': 48.62175,
            'lon': 20.15969
        },
        'zoom': 12
    },
    {
        'id': '100001051',
        'name': 'okres Rimavská Sobota',
        'sef': 'rimavska-sobota-okres',
        'geoPoint': {
            'lat': 48.46133,
            'lon': 20.01041
        },
        'zoom': 12
    },
    {
        'id': '100001052',
        'name': 'okres Veľký Krtíš',
        'sef': 'velky-krtis-okres',
        'geoPoint': {
            'lat': 48.17113,
            'lon': 19.31129
        },
        'zoom': 12
    },
    {
        'id': '100001053',
        'name': 'okres Zvolen',
        'sef': 'zvolen-okres',
        'geoPoint': {
            'lat': 48.52268,
            'lon': 19.19311
        },
        'zoom': 12
    },
    {
        'id': '100001054',
        'name': 'okres Žarnovica',
        'sef': 'zarnovica-okres',
        'geoPoint': {
            'lat': 48.4484,
            'lon': 18.6556
        },
        'zoom': 12
    },
    {
        'id': '100001055',
        'name': 'okres Žiar nad Hronom',
        'sef': 'ziar-nad-hronom-okres',
        'geoPoint': {
            'lat': 48.57865,
            'lon': 18.86604
        },
        'zoom': 12
    },
    {
        'id': '100000101',
        'isMain': true,
        'name': 'Bratislavský kraj',
        'sef': 'bratislavsky-kraj',
        'geoPoint': {
            'lat': 48.19355,
            'lon': 17.13661
        },
        'zoom': 11
    },
    {
        'id': '100001001',
        'name': 'okres Bratislava I',
        'sef': 'bratislava-i-okres',
        'geoPoint': {
            'lat': 48.14714,
            'lon': 17.10234
        },
        'zoom': 12
    },
    {
        'id': '100001002',
        'name': 'okres Bratislava II',
        'sef': 'bratislava-ii-okres',
        'geoPoint': {
            'lat': 48.13853,
            'lon': 17.1918
        },
        'zoom': 12
    },
    {
        'id': '100001003',
        'name': 'okres Bratislava III',
        'sef': 'bratislava-iii-okres',
        'geoPoint': {
            'lat': 48.20686,
            'lon': 17.14667
        },
        'zoom': 12
    },
    {
        'id': '100001004',
        'name': 'okres Bratislava IV',
        'sef': 'bratislava-iv-okres',
        'geoPoint': {
            'lat': 48.20445,
            'lon': 17.02843
        },
        'zoom': 12
    },
    {
        'id': '100001005',
        'name': 'okres Bratislava V',
        'sef': 'bratislava-v-okres',
        'geoPoint': {
            'lat': 48.06797,
            'lon': 17.11884
        },
        'zoom': 12
    },
    {
        'id': '100001006',
        'name': 'okres Malacky',
        'sef': 'malacky-okres',
        'geoPoint': {
            'lat': 48.44902,
            'lon': 17.10644
        },
        'zoom': 12
    },
    {
        'id': '100001007',
        'name': 'okres Pezinok',
        'sef': 'pezinok-okres',
        'geoPoint': {
            'lat': 48.31817,
            'lon': 17.28936
        },
        'zoom': 12
    },
    {
        'id': '100001008',
        'name': 'okres Senec',
        'sef': 'senec-okres',
        'geoPoint': {
            'lat': 48.18946,
            'lon': 17.32599
        },
        'zoom': 12
    },
    {
        'id': '100000108',
        'isMain': true,
        'name': 'Košický kraj',
        'sef': 'kosicky-kraj',
        'geoPoint': {
            'lat': 48.71996,
            'lon': 21.31210
        },
        'zoom': 11
    },
    {
        'id': '100001069',
        'name': 'okres Gelnica',
        'sef': 'gelnica-okres',
        'geoPoint': {
            'lat': 48.79724,
            'lon': 20.79467
        },
        'zoom': 12
    },
    {
        'id': '100001074',
        'name': 'okres Košice - okolie',
        'sef': 'kosice-okolie-okres',
        'geoPoint': {
            'lat': 48.68464,
            'lon': 21.05655
        },
        'zoom': 12
    },
    {
        'id': '100001070',
        'name': 'okres Košice I',
        'sef': 'kosice-i-okres',
        'geoPoint': {
            'lat': 48.77186,
            'lon': 21.20407
        },
        'zoom': 12
    },
    {
        'id': '100001071',
        'name': 'okres Košice II',
        'sef': 'kosice-ii-okres',
        'geoPoint': {
            'lat': 48.6464,
            'lon': 21.2014
        },
        'zoom': 12
    },
    {
        'id': '100001072',
        'name': 'okres Košice III',
        'sef': 'kosice-iii-okres',
        'geoPoint': {
            'lat': 48.73814,
            'lon': 21.29723
        },
        'zoom': 12
    },
    {
        'id': '100001073',
        'name': 'okres Košice IV',
        'sef': 'kosice-iv-okres',
        'geoPoint': {
            'lat': 48.67219,
            'lon': 21.28525
        },
        'zoom': 12
    },
    {
        'id': '100001075',
        'name': 'okres Michalovce',
        'sef': 'michalovce-okres',
        'geoPoint': {
            'lat': 48.67875,
            'lon': 21.94727
        },
        'zoom': 12
    },
    {
        'id': '100001076',
        'name': 'okres Rožňava',
        'sef': 'roznava-okres',
        'geoPoint': {
            'lat': 48.71509,
            'lon': 20.4484
        },
        'zoom': 12
    },
    {
        'id': '100001077',
        'name': 'okres Sobrance',
        'sef': 'sobrance-okres',
        'geoPoint': {
            'lat': 48.77878,
            'lon': 22.28823
        },
        'zoom': 12
    },
    {
        'id': '100001078',
        'name': 'okres Spišská Nová Ves',
        'sef': 'spisska-nova-ves-okres',
        'geoPoint': {
            'lat': 48.92263,
            'lon': 20.57353
        },
        'zoom': 12
    },
    {
        'id': '100001079',
        'name': 'okres Trebišov',
        'sef': 'trebisov-okres',
        'geoPoint': {
            'lat': 48.6502,
            'lon': 21.66713
        },
        'zoom': 12
    },
    {
        'id': '100000104',
        'isMain': true,
        'name': 'Nitriansky kraj',
        'sef': 'nitriansky-kraj',
        'geoPoint': {
            'lat': 48.28816,
            'lon': 18.11988
        },
        'zoom': 11
    },
    {
        'id': '100001025',
        'name': 'okres Komárno',
        'sef': 'komarno-okres',
        'geoPoint': {
            'lat': 47.84582,
            'lon': 18.07423
        },
        'zoom': 12
    },
    {
        'id': '100001026',
        'name': 'okres Levice',
        'sef': 'levice-okres',
        'geoPoint': {
            'lat': 48.07013,
            'lon': 18.69889
        },
        'zoom': 12
    },
    {
        'id': '100001027',
        'name': 'okres Nitra',
        'sef': 'nitra-okres',
        'geoPoint': {
            'lat': 48.29667,
            'lon': 18.07423
        },
        'zoom': 12
    },
    {
        'id': '100001028',
        'name': 'okres Nové Zámky',
        'sef': 'nove-zamky-okres',
        'geoPoint': {
            'lat': 48.00413,
            'lon': 18.35629
        },
        'zoom': 12
    },
    {
        'id': '100001029',
        'name': 'okres Šaľa',
        'sef': 'sala-okres',
        'geoPoint': {
            'lat': 48.14238,
            'lon': 17.88445
        },
        'zoom': 12
    },
    {
        'id': '100001030',
        'name': 'okres Topoľčany',
        'sef': 'topolcany-okres',
        'geoPoint': {
            'lat': 48.53784,
            'lon': 18.1134
        },
        'zoom': 12
    },
    {
        'id': '100001031',
        'name': 'okres Zlaté Moravce',
        'sef': 'zlate-moravce-okres',
        'geoPoint': {
            'lat': 48.41013,
            'lon': 18.38876
        },
        'zoom': 12
    },
    {
        'id': '100000107',
        'isMain': true,
        'name': 'Prešovský kraj',
        'sef': 'presovsky-kraj',
        'geoPoint': {
            'lat': 49.06473,
            'lon': 21.42059
        },
        'zoom': 10
    },
    {
        'id': '100001056',
        'name': 'okres Bardejov',
        'sef': 'bardejov-okres',
        'geoPoint': {
            'lat': 49.31464,
            'lon': 21.29292
        },
        'zoom': 12
    },
    {
        'id': '100001057',
        'name': 'okres Humenné',
        'sef': 'humenne-okres',
        'geoPoint': {
            'lat': 49.01394,
            'lon': 21.96093
        },
        'zoom': 12
    },
    {
        'id': '100001058',
        'name': 'okres Kežmarok',
        'sef': 'kezmarok-okres',
        'geoPoint': {
            'lat': 49.12493,
            'lon': 20.50455
        },
        'zoom': 12
    },
    {
        'id': '100001059',
        'name': 'okres Levoča',
        'sef': 'levoca-okres',
        'geoPoint': {
            'lat': 49.03325,
            'lon': 20.65757
        },
        'zoom': 12
    },
    {
        'id': '100001060',
        'name': 'okres Medzilaborce',
        'sef': 'medzilaborce-okres',
        'geoPoint': {
            'lat': 49.18231,
            'lon': 21.96774
        },
        'zoom': 12
    },
    {
        'id': '100001061',
        'name': 'okres Poprad',
        'sef': 'poprad-okres',
        'geoPoint': {
            'lat': 49.11203,
            'lon': 20.11476
        },
        'zoom': 12
    },
    {
        'id': '100001062',
        'name': 'okres Prešov',
        'sef': 'presov-okres',
        'geoPoint': {
            'lat': 48.99883,
            'lon': 21.17383
        },
        'zoom': 12
    },
    {
        'id': '100001063',
        'name': 'okres Sabinov',
        'sef': 'sabinov-okres',
        'geoPoint': {
            'lat': 49.13873,
            'lon': 20.99416
        },
        'zoom': 12
    },
    {
        'id': '100001064',
        'name': 'okres Snina',
        'sef': 'snina-okres',
        'geoPoint': {
            'lat': 49.04171,
            'lon': 22.24466
        },
        'zoom': 12
    },
    {
        'id': '100001065',
        'name': 'okres Stará Ľubovňa',
        'sef': 'stara-lubovna-okres',
        'geoPoint': {
            'lat': 49.34724,
            'lon': 20.67341
        },
        'zoom': 12
    },
    {
        'id': '100001066',
        'name': 'okres Stropkov',
        'sef': 'stropkov-okres',
        'geoPoint': {
            'lat': 49.21413,
            'lon': 21.7
        },
        'zoom': 12
    },
    {
        'id': '100001067',
        'name': 'okres Svidník',
        'sef': 'svidnik-okres',
        'geoPoint': {
            'lat': 49.34458,
            'lon': 21.57668
        },
        'zoom': 12
    },
    {
        'id': '100001068',
        'name': 'okres Vranov nad Topľou',
        'sef': 'vranov-nad-toplou-okres',
        'geoPoint': {
            'lat': 48.91229,
            'lon': 21.6198
        },
        'zoom': 12
    },
    {
        'id': '100000103',
        'isMain': true,
        'name': 'Trenčiansky kraj',
        'sef': 'trenciansky-kraj',
        'geoPoint': {
            'lat': 48.88861,
            'lon': 18.09585
        },
        'zoom': 11
    },
    {
        'id': '100001016',
        'name': 'okres Bánovce nad Bebravou',
        'sef': 'banovce-nad-bebravou-okres',
        'geoPoint': {
            'lat': 48.73908,
            'lon': 18.27689
        },
        'zoom': 12
    },
    {
        'id': '100001017',
        'name': 'okres Ilava',
        'sef': 'ilava-okres',
        'geoPoint': {
            'lat': 48.9911,
            'lon': 18.26272
        },
        'zoom': 12
    },
    {
        'id': '100001018',
        'name': 'okres Myjava',
        'sef': 'myjava-okres',
        'geoPoint': {
            'lat': 48.7311,
            'lon': 17.55046
        },
        'zoom': 12
    },
    {
        'id': '100001019',
        'name': 'okres Nové Mesto nad Váhom',
        'sef': 'nove-mesto-nad-vahom-okres',
        'geoPoint': {
            'lat': 48.74015,
            'lon': 17.88572
        },
        'zoom': 12
    },
    {
        'id': '100001020',
        'name': 'okres Partizánske',
        'sef': 'partizanske-okres',
        'geoPoint': {
            'lat': 48.57698,
            'lon': 18.38008
        },
        'zoom': 12
    },
    {
        'id': '100001021',
        'name': 'okres Považská Bystrica',
        'sef': 'povazska-bystrica-okres',
        'geoPoint': {
            'lat': 49.12351,
            'lon': 18.47379
        },
        'zoom': 12
    },
    {
        'id': '100001022',
        'name': 'okres Prievidza',
        'sef': 'prievidza-okres',
        'geoPoint': {
            'lat': 48.8011,
            'lon': 18.53092
        },
        'zoom': 12
    },
    {
        'id': '100001023',
        'name': 'okres Púchov',
        'sef': 'puchov-okres',
        'geoPoint': {
            'lat': 49.15976,
            'lon': 18.25307
        },
        'zoom': 12
    },
    {
        'id': '100001024',
        'name': 'okres Trenčín',
        'sef': 'trencin-okres',
        'geoPoint': {
            'lat': 48.87287,
            'lon': 18.09328
        },
        'zoom': 12
    },
    {
        'id': '100000102',
        'isMain': true,
        'name': 'Trnavský kraj',
        'sef': 'trnavsky-kraj',
        'geoPoint': {
            'lat': 48.30103,
            'lon': 17.68661
        },
        'zoom': 11
    },
    {
        'id': '100001009',
        'name': 'okres Dunajská Streda',
        'sef': 'dunajska-streda-okres',
        'geoPoint': {
            'lat': 47.95946,
            'lon': 17.61961
        },
        'zoom': 12
    },
    {
        'id': '100001010',
        'name': 'okres Galanta',
        'sef': 'galanta-okres',
        'geoPoint': {
            'lat': 48.18881,
            'lon': 17.72595
        },
        'zoom': 12
    },
    {
        'id': '100001011',
        'name': 'okres Hlohovec',
        'sef': 'hlohovec-okres',
        'geoPoint': {
            'lat': 48.42319,
            'lon': 17.79961
        },
        'zoom': 12
    },
    {
        'id': '100001012',
        'name': 'okres Piešťany',
        'sef': 'piestany-okres',
        'geoPoint': {
            'lat': 48.59008,
            'lon': 17.78834
        },
        'zoom': 12
    },
    {
        'id': '100001013',
        'name': 'okres Senica',
        'sef': 'senica-okres',
        'geoPoint': {
            'lat': 48.61599,
            'lon': 17.09213
        },
        'zoom': 12
    },
    {
        'id': '100001014',
        'name': 'okres Skalica',
        'sef': 'skalica-okres',
        'geoPoint': {
            'lat': 48.76691,
            'lon': 17.18599
        },
        'zoom': 12
    },
    {
        'id': '100001015',
        'name': 'okres Trnava',
        'sef': 'trnava-okres',
        'geoPoint': {
            'lat': 48.45733,
            'lon': 17.584
        },
        'zoom': 12
    },
    {
        'id': '100000105',
        'isMain': true,
        'name': 'Žilinský kraj',
        'sef': 'zilinsky-kraj',
        'geoPoint': {
            'lat': 49.18395,
            'lon': 18.82644
        },
        'zoom': 11
    },
    {
        'id': '100001032',
        'name': 'okres Bytča',
        'sef': 'bytca-okres',
        'geoPoint': {
            'lat': 49.24569,
            'lon': 18.49489
        },
        'zoom': 12
    },
    {
        'id': '100001033',
        'name': 'okres Čadca',
        'sef': 'cadca-okres',
        'geoPoint': {
            'lat': 49.42069,
            'lon': 18.83114
        },
        'zoom': 12
    },
    {
        'id': '100001034',
        'name': 'okres Dolný Kubín',
        'sef': 'dolny-kubin-okres',
        'geoPoint': {
            'lat': 49.25024,
            'lon': 19.33554
        },
        'zoom': 12
    },
    {
        'id': '100001035',
        'name': 'okres Kysucké Nové Mesto',
        'sef': 'kysucke-nove-mesto-okres',
        'geoPoint': {
            'lat': 49.32175,
            'lon': 18.794
        },
        'zoom': 12
    },
    {
        'id': '100001036',
        'name': 'okres Liptovský Mikuláš',
        'sef': 'liptovsky-mikulas-okres',
        'geoPoint': {
            'lat': 49.07008,
            'lon': 19.64429
        },
        'zoom': 12
    },
    {
        'id': '100001037',
        'name': 'okres Martin',
        'sef': 'martin-okres',
        'geoPoint': {
            'lat': 49.06529,
            'lon': 18.92186
        },
        'zoom': 12
    },
    {
        'id': '100001038',
        'name': 'okres Námestovo',
        'sef': 'namestovo-okres',
        'geoPoint': {
            'lat': 49.41966,
            'lon': 19.33051
        },
        'zoom': 12
    },
    {
        'id': '100001039',
        'name': 'okres Ružomberok',
        'sef': 'ruzomberok-okres',
        'geoPoint': {
            'lat': 49.00668,
            'lon': 19.28759
        },
        'zoom': 12
    },
    {
        'id': '100001040',
        'name': 'okres Turčianske Teplice',
        'sef': 'turcianske-teplice-okres',
        'geoPoint': {
            'lat': 48.8206,
            'lon': 18.91081
        },
        'zoom': 12
    },
    {
        'id': '100001041',
        'name': 'okres Tvrdošín',
        'sef': 'tvrdosin-okres',
        'geoPoint': {
            'lat': 49.26642,
            'lon': 19.72352
        },
        'zoom': 12
    },
    {
        'id': '100001042',
        'name': 'okres Žilina',
        'sef': 'zilina-okres',
        'geoPoint': {
            'lat': 49.17605,
            'lon': 18.78661
        },
        'zoom': 12
    }
];

export default locations;