import {FC} from 'react';
import {Box, Container} from '@mui/material';
import {Magazine} from 'components/page/leadgeneration/components/success/magazine/Magazine';
import {Advantages} from 'components/page/leadgeneration/components/success/advantages/Advantages';
import {Head as SuccessPageHead} from 'components/page/leadgeneration/components/success/head/Head';

export const Success: FC = () => {
    return <Container maxWidth="lg" sx={{paddingTop: 3, paddingBottom: 10}}>

        <Box mb={3}>
            <SuccessPageHead/>
        </Box>

        <Box mb={5}>
            <Magazine/>
        </Box>

        <Box>
            <Advantages/>
        </Box>

    </Container>;
};
