import {FC} from 'react';
import Link from 'components/link/Link';
import {Text} from 'modules/theme/components/text/Text';
import {CheckboxWithLabel} from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';

interface ISelect {
    linkHref: string;
    linkText: string;
    isChecked: boolean;
    onChange: (isChecked: boolean) => void;
    errMsg?: string
}

export const Checkbox: FC<ISelect> = ({linkHref, linkText, isChecked, onChange, errMsg}) => {

    return <CheckboxWithLabel
        onClick={onChange}
        isChecked={isChecked}
        label={<Link openInNewTab href={linkHref}><Text variant="body2">{linkText}</Text></Link>}
        errMsg={errMsg}
    />;

}; 
