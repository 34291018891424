import {FC} from 'react';
import {Box, Palette} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';

interface ITem {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
}

export const Item: FC<ITem> = ({icon, title}) => {
    return <Box textAlign='center'>
        <Svg component={icon} width={80} height={80} />

        <Text variant="body2" center color={(palette: Palette) => palette.labelSecondary}>
            {title}
        </Text>
    </Box>;
};
