import {FC} from 'react';
import {Palette} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';

export const Description: FC = () => {

    return <Text variant="body2" center color={(palette: Palette) => palette.labelSecondary}>
        V našom online magazíne nájdete množstvo článkov a podcastov o predaji i kúpe nehnuteľností.
    </Text>;
};
