import {FC} from 'react';
import {Box} from '@mui/material';
import {Headline} from 'components/page/leadgeneration/components/success/head/text/Headline';
import {Description} from 'components/page/leadgeneration/components/success/head/text/Description';
import {Notification} from 'components/page/leadgeneration/components/success/head/notification/Notification';

export const Head: FC = () => {
    return <>
        <Box mb={{xs: 2, md: 8}}>
            <Notification/>
        </Box>

        <Box maxWidth={580} m="auto" mb={3}>
            <Headline/>
        </Box>

        <Box maxWidth={495} m="auto">
            <Description/>
        </Box>
    </>;
};
