import {FC} from 'react';
import {Select as CommonSelect} from 'modules/theme/components/fields/select/Select';
import {Label} from 'components/page/leadgeneration/components/initial/form/fields/common/Label';

interface ISelect {
    label: string;
    value: string;
    options: string[];
    errorMsg?: string;
    placeholder?: string;
    onChange: (value: string) => void;
}

export const Select: FC<ISelect> = ({label, options, value, errorMsg, onChange, placeholder}) => {
    return <>
        <Label text={label}/>

        <CommonSelect
            errorMsg={errorMsg}
            onChange={onChange}
            selectedValue={value}
            placeholder={placeholder}
            options={options.map(option => ({name: option, value: option}))}
        />
    </>;
}; 
