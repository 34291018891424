import {FC} from 'react';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Text} from 'modules/theme/components/text/Text';
import IconCheckGreen from 'components/page/leadgeneration/img/svg/check-green.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

export const Notification: FC = () => {
    const {palette} = useTheme();

    return <Box
        p={3}
        gap={2}
        width="100%"
        display="flex"
        borderRadius={1}
        border="1px solid"
        alignItems="center"
        borderColor={palette.backgroundPrimary.main}
        boxShadow={'0px 0px 10px 2px ' + palette.labelQuarternary.main}
        data-test-id='notification'
    >
        <Svg component={IconCheckGreen} width={50} height={50} />

        <Text variant="body2" bold>Formulár ste úspešne odoslali</Text>
    </Box>;
};
